import Vue from "vue";
import excel from "vue-excel-export";
import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import EncryptUtility from "../../utility/js/encrypt-utility";
import PartService from "./parts-service";
import commonService from "../../common/js/common-api-service";
import AddFavourite from "../../utility/js/addFav";
Vue.use(excel);

export default {
  data: () => ({
    max100Rule: [(v) => (v || "").length <= 100 || "Max length of 100 character"],
    name: "partsList",
    showAdd: false,
    isFormValid: false,
    exportDisable: true,
    backSlash: true,
    refresh: true,
    route: "",
    favouriteList: [],
    actionList: [],
    pageUrl: "",
    mainPage: "",
    subPage: "",
    userId: EncryptUtility.localStorageDecrypt("userID"),
    project: "",
    chkesn: "",
    chkiccid: "",
    partClass: [],
    chkIsActive: "",
    partType: [],
    partClassList: [],
    chkbcn: "",
    partTypeList: [],
    projectList: [],
    partsEditList: [],
    excelName: "",
    searchText: "",
    chkserial: "",
    totalRecords: 0,
    json_fields: {
      "Part Number": "Part_Number",
      Description: "Description",
      Project: "Project",
      Class: "Class",
      BCN: "IsBCN",
      Serial_No: "IsSerialNo",
      ESN: "IsESN",
      ICCID: "IsICCID",
      Active: "IsActive",
    },
    json_meta: [
      [
        {
          key: "charset",
          value: "utf-8",
        },
      ],
    ],
    headers: [
      {
        text: "Part Number",
        align: "start",
        value: "Part_Number",
        class: "primary customwhite--text",
        width: "10%",
      },
      {
        text: "Description",
        value: "Description",
        class: "primary customwhite--text",
        width: "20%",
      },
      {
        text: "Project",
        value: "Project",
        class: "primary customwhite--text",
        width: "10%",
      },
      {
        text: "Class",
        value: "Class",
        class: "primary customwhite--text",
        width: "10%",
      },

      {
        text: "BCN",
        value: "IsBCN",
        class: "primary customwhite--text",
        width: "7%",
      },
      {
        text: "Serial No",
        value: "IsSerialNo",
        class: "primary customwhite--text",
        width: "7%",
      },
      {
        text: "ESN",
        value: "IsESN",
        class: "primary customwhite--text",
        width: "7%",
      },
      {
        text: "ICCID",
        value: "IsICCID",
        class: "primary customwhite--text",
        width: "7%",
      },
      {
        text: "Active",
        value: "IsActive",
        class: "primary customwhite--text",
        width: "7%",
      },
      {
        text: "Actions",
        value: "actions",
        class: "primary customwhite--text",
        sortable: false,
        width: "5%",
      },
    ],
    activeList: [
      {
        text: " ",
        value: " ",
      },
      {
        text: "Yes",
        value: "Yes",
      },
      {
        text: "No",
        value: "No",
      },
    ],
    bcnList: [
      {
        text: " ",
        value: " ",
      },
      {
        text: "Yes",
        value: "Yes",
      },
      {
        text: "No",
        value: "No",
      },
    ],
    esnimeiList: [
      {
        text: " ",
        value: " ",
      },
      {
        text: "Yes",
        value: "Yes",
      },
      {
        text: "No",
        value: "No",
      },
    ],
    iccidList: [
      {
        text: " ",
        value: " ",
      },
      {
        text: "Yes",
        value: "Yes",
      },
      {
        text: "No",
        value: "No",
      },
    ],
    serialList: [
      {
        text: " ",
        value: " ",
      },
      {
        text: "Yes",
        value: "Yes",
      },
      {
        text: "No",
        value: "No",
      },
    ],
  }),

  async created() {
    let LoaderDialog = {
      visible: true,
      title: "Please Wait...",
    };
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.projectList = await commonService.getProject("get", this.userId);
    this.projectList = this.projectList.ProjectInfo;
    this.partClassList = await PartService.getPartClassListSearch("get", this.userId);
    this.getPartType();
    LoaderDialog.visible = false;
    this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
  },

  mounted() {
    this.getSearch();
  },
  methods: {
    //Reset Function
    resetFunction() {
      this.onSearchCancel();
      EncryptUtility.localStorageRemoveItem("PLProject");
      EncryptUtility.localStorageRemoveItem("PLProjectData");
      EncryptUtility.localStorageRemoveItem("PLSearchText");
      EncryptUtility.localStorageRemoveItem("PLPartType");
      EncryptUtility.localStorageRemoveItem("PLPartTypeData");
      EncryptUtility.localStorageRemoveItem("PLPartClass");
      EncryptUtility.localStorageRemoveItem("PLPartClassData");
      EncryptUtility.localStorageRemoveItem("PLIsActive");
      EncryptUtility.localStorageRemoveItem("PLBCN");
      EncryptUtility.localStorageRemoveItem("PLESN");
      EncryptUtility.localStorageRemoveItem("PLICCID");
      EncryptUtility.localStorageRemoveItem("PLSerial");
    },
    // To get the action List
    actionSetter(action) {
      if (this.actionList !== undefined && this.actionList !== null && this.actionList !== "") {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },

    // onclik of search
    async onSearchClick() {
      if (this.project == undefined || this.project == null || isNaN(this.project)) {
        this.project = "0";
      }
      let obj = {
        UserId: this.userId.toString(),
        SearchStr: this.searchText == null ? "" : this.searchText,
        proj_key: this.project ? parseInt(this.project) : 0,
        ptype_key: "[" + this.partType.toString() + "]",
        class_key: "[" + this.partClass.toString() + "]",
        IsActive: this.chkIsActive == "Yes" ? "Y" : this.chkIsActive == "No" ? "N" : "",
        IsBCN: this.chkbcn == "Yes" ? "Y" : this.chkbcn == "No" ? "N" : "",
        IsSerialNo: this.chkserial == "Yes" ? "Y" : this.chkserial == "No" ? "N" : "",
        IsESN: this.chkesn == "Yes" ? "Y" : this.chkesn == "No" ? "N" : "",
        IsICCID: this.chkiccid == "Yes" ? "Y" : this.chkiccid == "No" ? "N" : "",
      };
      let partList = await PartService.postSearchData("post", obj);
      if (partList.message !== "NA") {
        this.partsEditList = partList;
        this.totalRecords = partList.length;
        this.exportDisable = false;
        if (this.project !== null && this.project !== undefined && this.project !== "") {
          EncryptUtility.localStorageEncrypt("PLProject", this.project.toString());
          if (this.projectList.length !== 0) {
            EncryptUtility.localStorageEncrypt("PLProjectData", JSON.stringify(this.projectList));
          }
        }
        if (this.searchText !== null && this.searchText !== undefined && this.searchText !== "") {
          EncryptUtility.localStorageEncrypt("PLSearchText", this.searchText.toString());
        }
        if (this.partType !== null && this.partType !== undefined && this.partType.length !== 0) {
          EncryptUtility.localStorageEncrypt("PLPartType", JSON.stringify(this.partType));
          if (this.partTypeList.length !== 0) {
            EncryptUtility.localStorageEncrypt("PLPartTypeData", JSON.stringify(this.partTypeList));
          }
        }
        if (this.partClass !== null && this.partClass !== undefined && this.partType.length !== 0) {
          EncryptUtility.localStorageEncrypt("PLPartClass", JSON.stringify(this.partClass));
          if (this.partClassList.length !== 0) {
            EncryptUtility.localStorageEncrypt("PLPartClassData", JSON.stringify(this.partClassList));
          }
        }
        if (this.chkIsActive !== null && this.chkIsActive !== undefined && this.chkIsActive !== "") {
          EncryptUtility.localStorageEncrypt("PLIsActive", this.chkIsActive.toString());
        }
        if (this.chkbcn !== null && this.chkbcn !== undefined && this.chkbcn !== "") {
          EncryptUtility.localStorageEncrypt("PLBCN", this.chkbcn.toString());
        }
        if (this.chkesn !== null && this.chkesn !== undefined && this.chkesn !== "") {
          EncryptUtility.localStorageEncrypt("PLESN", this.chkesn.toString());
        }
        if (this.chkiccid !== null && this.chkiccid !== undefined && this.chkiccid !== "") {
          EncryptUtility.localStorageEncrypt("PLICCID", this.chkiccid.toString());
        }
        if (this.chkserial !== null && this.chkserial !== undefined && this.chkserial !== "") {
          EncryptUtility.localStorageEncrypt("PLSerial", this.chkserial.toString());
        }
      } else {
        this.partsEditList = [];
        this.totalRecords = 0;
        this.exportDisable = true;
      }
    },

    //Loading Local Storage items for search persistance
    getSearch() {
      if (EncryptUtility.localStorageDecrypt("PLProject") !== null && EncryptUtility.localStorageDecrypt("PLProject") !== undefined) {
        this.project = parseInt(EncryptUtility.localStorageDecrypt("PLProject"));
        if (EncryptUtility.localStorageDecrypt("PLProjectData") !== null && EncryptUtility.localStorageDecrypt("PLProjectData") !== undefined) {
          this.projectList = JSON.parse(EncryptUtility.localStorageDecrypt("PLProjectData"));
          this.onSearchClick();
        }
      }
      if (EncryptUtility.localStorageDecrypt("PLSearchText") !== null && EncryptUtility.localStorageDecrypt("PLSearchText") !== undefined) {
        this.searchText = EncryptUtility.localStorageDecrypt("PLSearchText");
        this.onSearchClick();
      }
      if (EncryptUtility.localStorageDecrypt("PLPartType") !== null && EncryptUtility.localStorageDecrypt("PLPartType") !== undefined) {
        this.partType = JSON.parse(EncryptUtility.localStorageDecrypt("PLPartType"));
        if (EncryptUtility.localStorageDecrypt("PLPartTypeData") !== null && EncryptUtility.localStorageDecrypt("PLPartTypeData") !== undefined) {
          this.partTypeList = JSON.parse(EncryptUtility.localStorageDecrypt("PLPartTypeData"));
          this.onSearchClick();
        }
      }
      if (EncryptUtility.localStorageDecrypt("PLPartClass") !== null && EncryptUtility.localStorageDecrypt("PLPartClass") !== undefined) {
        this.partClass = JSON.parse(EncryptUtility.localStorageDecrypt("PLPartClass"));
        if (EncryptUtility.localStorageDecrypt("PLPartClassData") !== null && EncryptUtility.localStorageDecrypt("PLPartClassData") !== undefined) {
          this.partClassList = JSON.parse(EncryptUtility.localStorageDecrypt("PLPartClassData"));
          this.onSearchClick();
        }
      }
      if (EncryptUtility.localStorageDecrypt("PLIsActive") !== null && EncryptUtility.localStorageDecrypt("PLIsActive") !== undefined) {
        this.chkIsActive = EncryptUtility.localStorageDecrypt("PLIsActive");
        this.onSearchClick();
      }
      if (EncryptUtility.localStorageDecrypt("PLBCN") !== null && EncryptUtility.localStorageDecrypt("PLBCN") !== undefined) {
        this.chkbcn = EncryptUtility.localStorageDecrypt("PLBCN");
        this.onSearchClick();
      }
      if (EncryptUtility.localStorageDecrypt("PLESN") !== null && EncryptUtility.localStorageDecrypt("PLESN") !== undefined) {
        this.chkesn = EncryptUtility.localStorageDecrypt("PLESN");
        this.onSearchClick();
      }
      if (EncryptUtility.localStorageDecrypt("PLICCID") !== null && EncryptUtility.localStorageDecrypt("PLICCID") !== undefined) {
        this.chkiccid = EncryptUtility.localStorageDecrypt("PLICCID");
        this.onSearchClick();
      }
      if (EncryptUtility.localStorageDecrypt("PLSerial") !== null && EncryptUtility.localStorageDecrypt("PLSerial") !== undefined) {
        this.chkserial = EncryptUtility.localStorageDecrypt("PLSerial");
        this.onSearchClick();
      }
    },

    // File name for Downloading excel
    fileName() {
      this.excelName =
        "Parts_Edit_List_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".xls";
    },

    // onClick of cancel
    onSearchCancel() {
      this.project = "";
      this.chkesn = "";
      this.chkiccid = "";
      this.partClass = [];
      this.chkIsActive = "";
      this.partType = [];
      this.chkserial = "";
      this.chkbcn = "";
      this.partsEditList = [];
      this.totalRecords = 0;
      this.searchText = "";
      this.project = "";
      this.exportDisable = true;
      EncryptUtility.localStorageRemoveItem("PLProject");
      EncryptUtility.localStorageRemoveItem("PLProjectData");
      EncryptUtility.localStorageRemoveItem("PLSearchText");
      EncryptUtility.localStorageRemoveItem("PLPartType");
      EncryptUtility.localStorageRemoveItem("PLPartTypeData");
      EncryptUtility.localStorageRemoveItem("PLPartClass");
      EncryptUtility.localStorageRemoveItem("PLPartClassData");
      EncryptUtility.localStorageRemoveItem("PLIsActive");
      EncryptUtility.localStorageRemoveItem("PLBCN");
      EncryptUtility.localStorageRemoveItem("PLESN");
      EncryptUtility.localStorageRemoveItem("PLICCID");
      EncryptUtility.localStorageRemoveItem("PLSerial");
    },

    //on Click of add Button
    newAddClicked() {
      this.$router.push(`/parts-add`);
    },

    // Add to Favouites
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },

    partEditDetails(item) {
      let id = item.part_id;
      this.$router.push({
        name: "appPartsDetails",
        params: {
          id: btoa(id.toString()),
        },
      });
    },

    //Part Type details on selection of project
    async getPartType() {
      this.partTypeList = "";
      let project = 0;
      let isAdd = false; //Do Not Remove - We are setting false for not add screen
      let partTypeData = await PartService.getPartType("get", project, isAdd);
      this.partTypeList = partTypeData;
    },
  },
  components: {
    breadcrumbComp,
  },
};
